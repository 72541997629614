import type { AppsignalOptions } from "@drieam/appsignal";
import { getAppsignalClient } from "@drieam/appsignal";

function getClient(): ReturnType<typeof getAppsignalClient> {
  // Act as a sort of singleton method
  if (window.ENV && window.ENV.APPSIGNAL_CLIENT) {
    return window.ENV.APPSIGNAL_CLIENT;
  }

  // Get config from window
  const config = window.ENV.APPSIGNAL_CONFIG || ({} as AppsignalOptions);

  const client = getAppsignalClient({
    ...config,
    key: import.meta.env.APPSIGNAL_JS_API_KEY,
    revision: import.meta.env.HEROKU_SLUG_COMMIT,
  });

  // Dynamically add metadata if an error occurs
  client.addDecorator((span) => {
    // The body tag contains all our data attributes
    const body = document.getElementsByTagName("body")[0];

    if (!body) {
      return span;
    }

    return span.setTags({
      user_id: body.getAttribute("data-user-id")!,
    });
  });

  // Save client to window so you can reuse it.
  window.ENV.APPSIGNAL_CLIENT = client;

  return client;
}

const appsignalClient = getClient();

export default appsignalClient;
